<template>
    <div class="content">
        <h2 class="title is-4">Update Your Availbility</h2>

        <vc-date-picker :mode="mode" :attributes="attributes" @dayclick="dayClicked()"  v-model="date" is-expanded color="blue" :min-date="new Date()"/>
        <br /><br />
        <div v-for="row in timeperiods" :key = "row.id" >
                  <a href="#" style="padding: 10px 0px; line-height: 25px;">
                  <span><strong>{{ row.label }}</strong> ({{row.time }}) </span>
                  <toggle-button  class="badge" style="float:right" :value="row.selected" />
                  </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {
      
    },
    data: function () {
          return {
                  
                  API_NAME: process.env.VUE_APP_API_NAME,
                  accessToken: '',
                  registered: false,
                  date: '',
                  timeperiods: [{'id': 1, 'label':'AM Only', 'time':'07:00-15:30', 'selected': false}, 
                {'id': 2, 'label':'Long Day', 'time':'07:00-21:30', 'selected': false},
                {'id': 3, 'label':'Main Day', 'time':'07:00-18:00', 'selected': false},
                {'id': 4, 'label':'PM Only', 'time':'12:00-21:30', 'selected': false},
                {'id': 5, 'label':'Twilight', 'time':'15:00-02:30', 'selected': false},
                {'id': 6, 'label':'Night Shift', 'time':'19:30-08:30', 'selected': false}],
                 
          }
      },
    methods: {
        registerInterest() {
            this.registered = true
        },
        dayClicked() {

        }
    }
}
</script>