<template>
    <div class="content">
        <h2 class="title">Upcoming Shifts</h2>

        <div class="box">
            <p><strong>Fruithill Private Nursing Home</strong><br />
            20/1/2023 (20:00 - 08:00)</p>
            <button class="button is-success is-small" @click="registerInterest()" v-if="registered==false">Register Interest</button>
            <button class="button is-warning is-small"  v-if="registered==true">Already Expressed Interest</button>
        </div>

        <div class="box">
            <p><strong>Belfast City Hospital - Cardiac Ward</strong><br />
            22/1/2023 (20:00 - 08:00)</p>
            <button class="button is-success is-small">Register Interest</button>
        </div>
        
        <router-link to="/"><button class="button is-info">Home</button></router-link>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {
      
    },
    data: function () {
          return {
                  
                  API_NAME: process.env.VUE_APP_API_NAME,
                  accessToken: '',
                  registered: false
                 
          }
      },
    methods: {
        registerInterest() {
            this.registered = true
        }
    }
}
</script>